var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            {
              staticClass: "pa-0",
              staticStyle: { "max-width": "1024px", height: "100%" }
            },
            [
              _c(
                "v-overlay",
                {
                  staticStyle: { "z-index": "999" },
                  attrs: {
                    absolute: _vm.$loading.absolute,
                    value: _vm.$loading.state
                  }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" }
                  })
                ],
                1
              ),
              _c(
                "v-fade-transition",
                { attrs: { mode: "out-in" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }